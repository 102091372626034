import * as React from "react"
import {Helmet} from 'react-helmet'
import hero from "../images/hero.png"
import styled from '@emotion/styled'
import {Footer, GlobalPageStyles, PageHeader} from "../components/common";
import { StaticImage } from "gatsby-plugin-image"

const PageStyles = styled.div`
  color: #232129;
  fontFamily: " Roboto, sans-serif, serif",
`

const FirstBlock = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const Section = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;

  @media (max-width: 720px) {
    flex-direction: column;
    align-items: center;
  }
`

const Text = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const HeroImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;

  @media (max-width: 720px) {
    max-width: 330px;
    width: 100%;
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  background: url("${hero}") no-repeat center;
  background-size: contain;

  @media (max-width: 720px) {
    padding-bottom: 200%;
    box-sizing: border-box;
  }
`

const Title = styled.div`
  margin-bottom: 40px;
  font-weight: 300;
  font-size: 48px;
  max-width: 535px;

  @media (max-width: 720px) {
    font-size: 30px;
    margin-bottom: 20px;
  }
`

const Description = styled.div`
  font-weight: 300;
  font-size: 24px;
  max-width: 535px;

  @media (max-width: 720px) {
    font-size: 18px;
    max-width: 330px;
  }
`

const Buttons = styled.div`
  width: 100%;
  max-width: 535px;
  display: flex;
  justify-content: flex-start;
  margin-top: 40px;

  @media (max-width: 720px) {
    justify-content: center;
    margin-top: 30px;
  }
`


const IndexPage = () => {
  return (
    <PageStyles>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>DFace - Seamless AI Face Effects</title>
      </Helmet>
      <GlobalPageStyles/>
      <FirstBlock>
        <PageHeader/>
        <Section>
          <Text>
            <Title>
              Seamless AI Face Effects
            </Title>
            <Description>
              DFace effects allow you to create different personas for your video sketches. Change age,
              gender, geometry of your face or turn yourself into a cartoon character.
            </Description>
            <Buttons>
              <a href="https://apps.apple.com/us/app/dface-ai-face-camera/id1567813268" target="_blank">
                <StaticImage
                  src="../images/store.png"
                  alt="Download DFace from App Store"
                  placeholder="blurred"
                  layout="fixed"
                  width={199}
                />
              </a>
            </Buttons>
          </Text>
          <HeroImage>
            <ImageWrapper>
            </ImageWrapper>
          </HeroImage>
        </Section>
      </FirstBlock>
      <Footer/>
    </PageStyles>
  )
}

export default IndexPage
